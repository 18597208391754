import pick from 'lodash-es/pick';
import Cookies from 'universal-cookie';
import { GDPRCookie, GDPR_PREFERENCES_COOKIE } from '@wkda/gdprlib';
import { getQueryParams } from '@wkda/funnel-utils';
import { getReferrer } from './referer';
import { setInspectionSEOCookie } from './cidQueue';
// TODO: Move everything related CID cookie to the napi server
const CID_COOKIE_KEY = 'WKDA';
const PHP_SESSION_ID_KEY = 'PHPSESSID';
const validParams = ['ap', 'cl', 'cr', 'dv', 'im', 'kw', 'loc', 'ma', 'mf', 'ms', 'mt', 'nw', 'pl', 'pn', 'ts', 'dlp', 'tms', 'utm_expid', 'MID'];
const setCookies = (cookieService, res, cidToSet, options) => {
  if (res) {
    options ? res.cookie(CID_COOKIE_KEY, cidToSet, options) : res.cookie(CID_COOKIE_KEY, cidToSet);
  } else {
    // @ts-ignore
    cookieService.set(CID_COOKIE_KEY, cidToSet, options);
  }
};
function getDomainName(req) {
  const hostname = typeof window === 'undefined' ? req.hostname : window.location.hostname;
  const parts = hostname.split('.');
  const domain = parts.length > 2 ? parts[parts.length - 2] + "." + parts[parts.length - 1] : hostname;
  return domain;
}

/**
 * If the CID cookie is not set in the browser (perhaps because GDPR doesn't allow it),
 * fetch it from PHP instead.
 */
async function getCurrentSEOCookie(_ref) {
  let {
    cookieService,
    getCIDSessionData,
    phpSessionId
  } = _ref;
  const cookieCID = cookieService.get(CID_COOKIE_KEY);
  if (cookieCID) {
    return cookieCID;
  }
  try {
    const response = await getCIDSessionData(phpSessionId);
    return response.data.cid;
  } catch (error) {
    return cookieCID;
  }
}

/**
 * Express is using milliseconds for `maxAge` value for some reason
 * (standard value should be in seconds), so we need to provide a proper value
 */
function getCookieMaxAge(isExpress) {
  const COOKIE_EXPIRY_IN_DAYS = 730; // 2 Years
  const maxAgeInSeconds = COOKIE_EXPIRY_IN_DAYS * 24 * 60 * 60;
  return isExpress ? maxAgeInSeconds * 1000 : maxAgeInSeconds;
}
const makeCookieOptions = req => ({
  maxAge: getCookieMaxAge(Boolean(req)),
  httpOnly: false,
  sameSite: 'strict',
  path: '/',
  domain: getDomainName(req)
});
async function initializeGdprService(_ref2) {
  let {
    cookieService,
    isProduction
  } = _ref2;
  try {
    const gdprService = await GDPRCookie.init(cookieService.getAll(), cookieService, isProduction);
    const isAnalyticalCookiesAreAllowed = gdprService.getUserCategories().includes('analytical');
    return {
      gdprService,
      isAnalyticalCookiesAreAllowed
    };
  } catch {
    return {
      gdprService: undefined,
      isAnalyticalCookiesAreAllowed: false
    };
  }
}
const getPhpSessionIdCookie = (cookies, res) => cookies.get(PHP_SESSION_ID_KEY) || (// @ts-expect-error
res === null || res === void 0 ? void 0 : res[PHP_SESSION_ID_KEY]);
export async function trackCidAsCookie(_ref3) {
  let {
    config,
    req,
    res,
    env,
    getCIDSessionData,
    setCIDSessionData,
    getPartial,
    logger,
    apmLogger
  } = _ref3;
  // CID logic is complex, the whole process is defined here https://wkdauto.atlassian.net/wiki/spaces/CJ/pages/3393978390/CID+service
  const queryParams = getQueryParams(req);
  const params = pick(queryParams, validParams);
  const referrer = getReferrer({
    req
  });
  const cookieService = req ? new Cookies(req.cookies) : new Cookies();
  const {
    locale,
    cmsUrl
  } = config;
  const isProduction = env === 'prod' || env === 'preprod';
  const isGdprCookieSet = !!cookieService.get(GDPR_PREFERENCES_COOKIE);
  const phpSessionId = getPhpSessionIdCookie(cookieService, res);
  const {
    gdprService
  } = await initializeGdprService({
    cookieService,
    isProduction
  });
  if (!phpSessionId) {
    const message = "[CID TRACKING] Cannot get PHP session id cookie";
    apmLogger === null || apmLogger === void 0 || apmLogger.log('[CID TRACKING]', 'track-cid-cookie', message);
    if (logger !== null && logger !== void 0 && logger.info) {
      logger.info({
        message
      });
    }
  }
  const isCidCookieForbiddenByGdpr = !isGdprCookieSet || isGdprCookieSet && gdprService && !gdprService.isPermitted(CID_COOKIE_KEY);
  let seoCmsCid;
  try {
    // If the referrer doesn't exist, or is an external site, we should check CMS for an SEO CID
    if (cmsUrl && (!referrer || req !== null && req !== void 0 && req.hostname && !referrer.includes(req === null || req === void 0 ? void 0 : req.hostname))) {
      const cmsResponse = await getPartial(cmsUrl, locale);
      seoCmsCid = cmsResponse['seo_cid'];
    }
  } catch (err) {
    seoCmsCid = undefined;
  }
  const {
    MID,
    utm_expid,
    ...otherParams
  } = params;
  const trackingParams = {
    ...otherParams,
    utm_expid
  };
  const currentSEOCookie = await getCurrentSEOCookie({
    cookieService,
    getCIDSessionData,
    phpSessionId
  });
  const cidToSet = setInspectionSEOCookie({
    currentSEOCookie,
    MID,
    locale,
    trackingParams,
    seoCmsCid
  });

  // Set new CID back in PHP
  try {
    await setCIDSessionData(phpSessionId, cidToSet);
  } catch (e) {
    const message = "[CID ERROR] Session data set. Error message: " + (e === null || e === void 0 ? void 0 : e.message);
    if (logger !== null && logger !== void 0 && logger.error) {
      logger.error({
        error: e,
        message
      });
    } else {
      var _res$locals$log$error, _res$locals;
      (_res$locals$log$error = res === null || res === void 0 || (_res$locals = res.locals) === null || _res$locals === void 0 || (_res$locals = _res$locals.log) === null || _res$locals === void 0 ? void 0 : _res$locals.error(message)) !== null && _res$locals$log$error !== void 0 ? _res$locals$log$error : console.error(message);
    }
  }

  // Set new CID in cookies if possible
  if (cidToSet && !isCidCookieForbiddenByGdpr) {
    setCookies(cookieService, res, cidToSet, req ? makeCookieOptions(req) : undefined);
  }
}