import { isConflictError, isConflictErrorWithFields } from '@wkda/funnel-utils';
import { hasGlobalError, isConflictStatusCode } from '../shared';
const PENDING_EVALUATION_ERROR = 'Customer has one or more pending self-evaluations';
const OLD_CAR_ERROR = 'Missing dat_ecode for carHash';
const LP_IDENTIFICATION_ERROR = 'Cannot identify license plate in vehicle-identification';
const INVALID_PHONE_ERROR = 'Invalid phone number';
const VIN_NOT_RECOGNIZED_ERROR = 'vinNotRecognized';
const VIN_ALREADY_SUBMITTED_ERROR = 'The VIN update was already submitted';
export function isPendingSelfEvalutationError(_ref) {
  let {
    result = {},
    statusCode
  } = _ref;
  return isConflictStatusCode(statusCode) && hasGlobalError(result, PENDING_EVALUATION_ERROR);
}
export function isOldCarError(_ref2) {
  let {
    result = {},
    statusCode
  } = _ref2;
  return isConflictStatusCode(statusCode) && hasGlobalError(result, OLD_CAR_ERROR);
}
export function isDOIError(obj) {
  const {
    result = {},
    statusCode
  } = obj;
  return isConflictStatusCode(statusCode) && result.inDoi === true;
}
export function isResubmitPriceAliveError(obj) {
  const {
    result = {},
    statusCode
  } = obj;
  return isConflictStatusCode(statusCode) && result.isResubmitPriceAlive === true;
}
export function isConflicErrorWithFields(obj) {
  const {
    statusCode,
    result = {}
  } = obj;
  return isConflictStatusCode(statusCode) &&
  // eslint-disable-next-line eqeqeq
  result.globalErrors != undefined &&
  // eslint-disable-next-line eqeqeq
  result.fieldErrors != undefined;
}
export function isLPIdentificationError(obj) {
  const {
    result = {},
    statusCode
  } = obj;
  return isConflictStatusCode(statusCode) && hasGlobalError(result, LP_IDENTIFICATION_ERROR);
}
export function isPhoneConflict(obj) {
  var _obj$response$data$fi, _obj$response;
  return isConflictErrorWithFields(obj) && ((_obj$response$data$fi = (_obj$response = obj.response) === null || _obj$response === void 0 ? void 0 : _obj$response.data.fieldErrors) !== null && _obj$response$data$fi !== void 0 ? _obj$response$data$fi : []).some(conflict => {
    return conflict.field === 'user.phoneNumber';
  });
}
export function isInvalidPhoneNumberError(obj) {
  var _obj$response2;
  return isConflictError(obj) && hasGlobalError((_obj$response2 = obj.response) === null || _obj$response2 === void 0 ? void 0 : _obj$response2.data, INVALID_PHONE_ERROR);
}
export function isVinConflict(obj) {
  var _obj$response3;
  return isConflictErrorWithFields(obj) && hasGlobalError(obj === null || obj === void 0 || (_obj$response3 = obj.response) === null || _obj$response3 === void 0 ? void 0 : _obj$response3.data, VIN_NOT_RECOGNIZED_ERROR);
}
export function isVinAlreadyUpdatedError(result) {
  var _result$response, _result$response2;
  return isConflictErrorWithFields(result) && (result === null || result === void 0 || (_result$response = result.response) === null || _result$response === void 0 ? void 0 : _result$response.data) && hasGlobalError((_result$response2 = result.response) === null || _result$response2 === void 0 ? void 0 : _result$response2.data, VIN_ALREADY_SUBMITTED_ERROR);
}